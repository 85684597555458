import { BusinessBranchStatus } from "../models/api/businesses";
import PagedFilterBase from "./PagedFilterBase";

class BusinessBranchFilter extends PagedFilterBase {
  Search?: string;
  Statuses: BusinessBranchStatus[] = [];
  IsFranchise?: boolean = false;
  FranchiseId?: string;

  constructor(init?: Partial<BusinessBranchFilter>) {
    super()
    
    if (init) Object.assign(this, init);
  }
}

export default BusinessBranchFilter