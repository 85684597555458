


















import BusinessBranchFilter from 'truemarket-modules/src/filters/BusinessBranchFilter'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import BusinessBranchPickerModalOptions from '@/app_code/Modals/Options/BusinessBranchPickerModalOptions'
import { BusinessBranchModel, BusinessBranchStatus } from 'truemarket-modules/src/models/api/businesses'
import DataTable from '../UI/Elements/DataTable/DataTable.vue'
import { DataTableColDef, DataTableDef, DataTableRowDef } from '@/app_code/DataTable'
import { Services } from 'truemarket-modules/src/services'
import { PagedResultSet } from 'truemarket-modules/src/models/api/common'

@Component({
  components: {
    DataTable
  }
})
export default class BusinessBranchPickerModal extends Vue {
  private filter: BusinessBranchFilter | null = null

  @Prop()
  private options!: BusinessBranchPickerModalOptions;

  private search = ''

  private dtDef = new DataTableDef(new DataTableRowDef((b) => b.BusinessBranchId, [
    new DataTableColDef('Status', (b) => b.FormattedStatus),
    new DataTableColDef('Business', (b) => b.Business.Name),
    new DataTableColDef('Branch', (b) => b.TradingName),
    new DataTableColDef('ABN', (b) => b.ABN)
  ]));

  private results: PagedResultSet<BusinessBranchModel> | null = null

  mounted () {
    this.filter = new BusinessBranchFilter({
      Statuses: this.options.Statuses ?? [BusinessBranchStatus.Active],
      IsFranchise: this.options.IsFranchise,
      FranchiseId: this.options.FranchiseId
    })
  }

  updateData () {
    if (this.filter === null) return

    Services.API.Businesses.SearchBranches(this.filter).then((businesses) => {
      this.results = businesses
    })
  }

  private debounceTimeout: number | undefined

  @Watch('search')
  handleUpdate () {
    clearTimeout(this.debounceTimeout)

    this.debounceTimeout = setTimeout(() => {
      this.updateData()
    }, 1000)
  }

  selectBranch (branchId: string) {
    if (this.options.Callback) this.options.Callback(branchId)

    this.$emit('close')
  }
}
